<template>
  <div>
    <v-card flat>
      <v-card-title>
        <v-row>
          <v-col>
            <h3 class="grey--text">
              <v-icon class="mr-2" large>mdi-laptop</v-icon>Units
            </h3></v-col
          >
          <v-spacer></v-spacer>
          <v-col cols="2">
            <addUnit
              :customer_id="customer_id"
              v-if="show_add_unit"
              @close="close('unit')"
          /></v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-divider class="mb-3"></v-divider>
        <v-data-table
          dense
          v-if="ready"
          :loading="!ready"
          label="Units"
          :items="units"
          :headers="headers"
        >
          <template v-slot:item.actions="{ item }">
            <div class="d-flex">
              <problems-modal :item="item" />
              <v-btn color="" icon @click="open_modal([item, 'update'])">
                <v-icon class="mr-2" color="warning">mdi-pencil</v-icon></v-btn
              >
              <v-btn
                color="red"
                icon
                @click="del_unit(item)"
                :loading="loading"
              >
                <v-icon class="mr-2">mdi-delete</v-icon></v-btn
              >
            </div>
          </template>

          <template v-slot:item.includes="{ item }">
            <ol>
              <li v-for="inc in JSON.parse(item.includes)" :key="inc">
                {{ inc }}
              </li>
            </ol>
          </template>

          <template v-slot:item.accessories="{ item }">
            <ol>
              <li v-for="acc in JSON.parse(item.accessories)" :key="acc">
                {{ acc }}
              </li>
            </ol>
          </template>
        </v-data-table>
        <h2 class="text-center" v-else>Data Table is loading..</h2>
      </v-card-text>
    </v-card>
    <ProblemsModal
      v-if="show_problem_modal"
      :item="selected_item"
      @close="close('problem_modal')"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProblemsModal from "../Problems/problemsModal.vue";
// import ProblemsModal from "../problemsModal.vue";
export default {
  components: { ProblemsModal },
  data() {
    return {
      loading: false,
      show_problem_modal: false,
      selected_item: {},
      show_add_unit: false,
      show_update_modal: false,
      dialog: true,
      ready: false,
      headers: [
        { text: "", value: "actions", width: 100 },
        { text: "id", value: "id" },
        { text: "Unit Type", value: "unit_type" },
        { text: "Unit Brand", value: "unit_brand" },
        { text: "Unit Model", value: "unit_model" },
        { text: "Serial Number", value: "serial_no" },
        { text: "Date Received", value: "date_received" },
        { text: "Accessories", value: "accessories" },
        { text: "Functionals", value: "includes" },
        { text: "Picked Up Date", value: "picked_up_date" },
        { text: "Picked Up By", value: "picked_up_by" },
      ],
    };
  },
  props: ["customer_id"],
  methods: {
    async del_unit(item) {
      this.loading = true;
      await this.delete_unit(item);
      this.loading = false;
      alert("Successfully deleted a unit");
    },
    open_modal(request) {
      this.selected_item = request[0];
      if (request[1] == "problem") this.show_problem_modal = true;
      if (request[1] == "update") this.show_update_modal = true;
    },
    close(type) {
      if (type == "customer") return (this.show_edit_profile = false);
      if (type == "unit") return (this.show_add_unit = false);
      if (type == "add_invoice") return (this.show_add_invoice = false);
      if (type == "invoice_card") return (this.show_invoice_card = false);
      if (type == "unit") return (this.show_unit = false);
      if (type == "update_modal") return (this.show_update_modal = false);
      if (type == "problem_modal") return (this.show_problem_modal = false);
      this.$emit("close");
    },
    ...mapActions({
      get_units: "unit/get_units",
      delete_unit: "unit/delete_unit",
    }),
  },
  computed: {
    customer() {
      var index = this.customers.findIndex((a) => a.id == this.customer_id);
      return this.customers[index];
    },
    ...mapGetters({
      units: "unit/units",
      customers: "customer/customers",
    }),
  },
  async created() {
    this.ready = false;
    await this.get_units(this.customer_id);
    this.ready = true;
  },
};
</script>
<style lang="scss" scoped></style>
